<template>
  <div class="sidebar">
    <div class="cell">
      <img class="head_img"
           src="@/assets/img/logo.png"
           alt="">
    </div>
    <el-menu :default-active="onRoutes"
             class="el-menu-vertical-demo"
             background-color="#0C1735"
             active-text-color="#fff"
             text-color="#B8C7CE"
             router>
      <div v-for="(item,index) in routeList"
           :key="index">
        <el-sub-menu v-if="item.children.length && item.isShow"
                     :index="item.index">
          <template #title>
            <img class="menu_icon"
                 :src="onRoutes==item.index?item.icon_active:item.icon"
                 alt="">
            <span>{{item.name}}</span>
          </template>
          <el-menu-item v-for="(items,indexs) in item.children"
                        :key="indexs"
                        :index="items.index">{{items.name}}</el-menu-item>
        </el-sub-menu>
        <el-menu-item v-if="!item.children.length && item.isShow"
                      :index="item.index">
          <img class="menu_icon"
               :src="onRoutes==item.index?item.icon_active:item.icon"
               alt="">
          {{item.name}}
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collapse: false,
      routeList: [
        {
          index: "index",
          icon: require("@/assets/img/menu/icon_home.png"),
          icon_active: require("@/assets/img/menu/icon_home.png"),
          name: "工作台",
          isShow: true,
          children: [],
        },
        {
          index: "advertisement",
          icon: require("@/assets/img/menu/icon_ad.png"),
          icon_active: require("@/assets/img/menu/icon_ad.png"),
          name: "广告数据概览",
          isShow: true,
          children: [
            {
              index: "adClick",
              name: "广告入口点击",
            },
            {
              index: "consult",
              name: "开户咨询量",
            },
          ],
        },
        // {
        //   index: "kaihu",
        //   icon: require("@/assets/img/menu/icon_data.png"),
        //   icon_active: require("@/assets/img/menu/icon_data.png"),
        //   name: "开户数据管理",
        //   isShow: true,
        //   children: [],
        // },
        {
          index: "tuiguang",
          icon: require("@/assets/img/menu/icon_data.png"),
          icon_active: require("@/assets/img/menu/icon_data.png"),
          name: "推广数据",
          isShow:
            JSON.parse(localStorage.getItem("qihuo_info")).company_type ==
            "xinhu"
              ? false
              : true,
          children: [],
        },
      ],
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.sidebar {
  display: block;
  width: 260px;
  height: 100vh;
  background-color: #0c1735;
}

.cell {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head_img {
  height: 40px;
}

.menu_icon {
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 12px;
}

.el-menu {
  border-right: 0;
}

/* .sidebar::-webkit-scrollbar {
  width: 0;
} */

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}

.el-menu--collapse {
  width: 100%;
}

/* .el-menu-item {
  height: 80px;
  font-size: 18px;
} */

/* .el-menu-item * {
  vertical-align: middle !important;
} */

.el-menu-item.is-active {
  background-color: #384159;
}

.red_point {
  position: absolute;
  top: 10px;
  right: -5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: red;
}
</style>
