import request from "@/utils/request";

// 账号密码登入
export const login = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/login",
    method: "POST",
    data: query,
  });
};
// 工作台数据
export const getWorkbench = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/workbench",
    method: "POST",
    data: query,
  });
};
// 工作台开户数图表
export const openUserCharts = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/openUserCharts",
    method: "POST",
    data: query,
  });
};
// 工作台右侧上方图表
export const adAndOpenPhoneCharts = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/adAndOpenPhoneCharts",
    method: "POST",
    data: query,
  });
};
// 工作台右侧下方图表
export const platformCharts = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/platformCharts",
    method: "POST",
    data: query,
  });
};
// 广告入口点击
export const getAdDataList = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/adData",
    method: "POST",
    data: query,
  });
};
// 添加广告备注
export const addAdRemark = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/addAdRemark",
    method: "POST",
    data: query,
  });
};
// 广告点击图表
export const adCharts = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/adCharts",
    method: "POST",
    data: query,
  });
};
// 开户咨询量
export const getOpenAccountDataList = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/openPhoneData",
    method: "POST",
    data: query,
  });
};
// 更改开户量咨询状态
export const changeOpenPhoneStatus = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/changeOpenPhoneStatus",
    method: "POST",
    data: query,
  });
};
// 开户数据管理
export const getOpenUserDataList = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/openUserData",
    method: "POST",
    data: query,
  });
};
// 添加开户数据
export const addOpenUser = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/addOpenUser",
    method: "POST",
    data: query,
  });
};
// 推广数据
export const getClueDataList = (query) => {
  return request({
    url: "/adminapi/qihuogongsi.index/clueData",
    method: "POST",
    data: query,
  });
};