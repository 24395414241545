<template>
  <div>
    <div class="search_header">
      <div class="flex at_c mr30">
        <div class="mr10">日期筛选</div>
        <el-date-picker v-model="timeArr"
                        type="daterange"
                        unlink-panels
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="YYYY/MM/DD"
                        value-format="YYYY-MM-DD"
                        :shortcuts="shortcuts" />
      </div>
      <div>
        <el-button @click="searchList"
                   type="primary">查询</el-button>
        <el-button @click="reset"
                   type="">重置</el-button>
      </div>
    </div>
    <div class="handle_btns">
      <img @click="dialogFormVisible = true"
           class="btn_add_img"
           src="@/assets/img/btn_add.png"
           alt="">
    </div>
    <div class="tabel_data">
      <el-table :data="tableData"
                height="60vh"
                style="width: 100%"
                v-loading="loading"
                border>
        <!-- <el-table-column label="ID"
                         align="center"
                         width="180">
          <template #default="scope">
            <span class="platform_text">{{scope.row.id}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="create_time"
                         label="时间"
                         align="center"
                         width="180" />
        <el-table-column prop="name"
                         label="姓名"
                         align="center"
                         width="140">
        </el-table-column>
        <el-table-column prop="mobileText"
                         label="手机号"
                         align="center"
                         width="180">
        </el-table-column>
        <el-table-column label="开户状态"
                         align="center"
                         fixed="right"
                         width="240">
          <template #default="scope">
            <div class="flex at_c jc_c">
              <span class="status1"
                    v-if="scope.row.status==0">审核中</span>
              <span @click="copyText(scope.row.name + '\n' + scope.row.mobile)"
                    class="btn_copy"
                    v-if="scope.row.status==0">复制</span>

              <span class="status2"
                    v-if="scope.row.status==1">已开户</span>
              <span class="kaihuhao"
                    v-if="scope.row.status==1">{{scope.row.asset}}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="flex at_c jc_e mt20">
        <el-pagination background
                       layout="total, prev, pager, next"
                       :current-page="page"
                       :page-size="limit"
                       :total="total"
                       @current-change="handleCurrentChange" />
      </div>
    </div>

    <el-dialog v-model="dialogFormVisible"
               title="新增"
               width="420px"
               @closed="closeForm">
      <el-form :model="form">
        <el-form-item label="姓名"
                      :label-width="formLabelWidth">
          <el-input v-model="form.name"
                    placeholder="请输入姓名"
                    autocomplete="off" />
        </el-form-item>
        <el-form-item label="手机号"
                      :label-width="formLabelWidth">
          <el-input v-model="form.mobile"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    placeholder="请输入手机号"
                    autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeForm">取消</el-button>
          <el-button type="primary"
                     @click="submit">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/common";
export default {
  data() {
    return {
      timeArr: "",
      shortcuts: [
        {
          text: "今日",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
            return [end, start];
          },
        },
        {
          text: "昨日",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            return [start, end];
          },
        },
        {
          text: "最近7天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "最近30天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      limit: 0,
      loading: false,

      dialogFormVisible: false,
      formLabelWidth: "80px",
      form: {
        name: "",
        mobile: "",
      },
    };
  },
  mounted() {
    this.getOpenUserDataList(1);
  },
  methods: {
    async getOpenUserDataList(page) {
      this.loading = true;
      var start_time = "";
      var end_time = "";
      if (this.timeArr) {
        start_time = this.timeArr[0];
        end_time = this.timeArr[1];
      }
      const res = await Service.getOpenUserDataList({
        page,
        start_time,
        end_time,
      });
      var tableData = res.data.data;
      const reg = /(\d{3})\d{4}(\d{4})/;
      for (const item of tableData) {
        item.mobileText = item.mobile.replace(reg, "$1****$2")
      }
      this.tableData = tableData;
      this.total = res.data.total;
      this.limit = res.data.per_page;
      this.loading = false;
    },
    searchList() {
      this.page = 1;
      this.tableData = [];
      this.getOpenUserDataList(1);
    },
    reset() {
      this.timeArr = "";
      this.searchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.tableData = [];
      this.getOpenUserDataList(page);
    },
    closeForm() {
      this.dialogFormVisible = false;
      this.form.name = "";
      this.form.mobile = "";
    },
    async submit() {
      var name = this.form.name;
      var mobile = this.form.mobile;
      if (!name) {
        this.$message.error("姓名不可为空");
        return false;
      }
      if (
        mobile.trim().length != 11 ||
        !/^1[3|4|5|6|7|8|9]\d{9}$/.test(mobile)
      ) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      const res = await Service.addOpenUser({
        name,
        mobile,
      });
      this.$message.success(res.msg);
      this.closeForm();
      this.getOpenUserDataList();
    },
    // async copyText(e) {
    //   await navigator.clipboard.writeText(e);
    //   this.$message.success("已复制到剪贴板");
    // },
    copyText(text) {
      var textarea = document.createElement("textarea");
      textarea.style.position = "fixed";
      textarea.style.opacity = 0;
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message.success("已复制到剪贴板");
    },
  },
};
</script>

<style scoped>
.search_header {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 20px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  color: #707070;
}

.handle_btns {
  margin-bottom: 12px;
}

.btn_add_img {
  height: 32px;
  display: block;
  cursor: pointer;
}

.tabel_data {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
}

.platform_text {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
}

.icon_zoushi {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: 12px;
}

.btn_add {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
  cursor: pointer;
}

.btn_del {
  font-weight: 400;
  font-size: 14px;
  color: #ff1d24;
  cursor: pointer;
}

.status1 {
  font-weight: 400;
  font-size: 14px;
  color: #ffa200;
  margin-right: 30px;
}

.btn_copy {
  width: 56px;
  height: 24px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #4571e7;
}

.status2 {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
  margin-right: 30px;
}

.kaihuhao {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
}
</style>