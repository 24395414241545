import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/common/Home";
import NotFound from "@/views/404";
import Login from "@/views/Login";

import Index from "@/views/Index";
import Kaihu from "@/views/Kaihu";
import AdClick from "@/views/AdClick";
import Consult from "@/views/Consult";
import Tuiguang from "@/views/Tuiguang";

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/",
    component: Home,
    children: [
      {
        path: '/index',
        component: Index,
        meta: { title: '工作台' }
      },
      {
        path: '/adClick',
        component: AdClick,
        meta: { title: '广告入口点击' }
      },
      {
        path: '/consult',
        component: Consult,
        meta: { title: '开户咨询量' }
      },
      {
        path: '/kaihu',
        component: Kaihu,
        meta: { title: '开户数据管理' }
      },
      {
        path: '/tuiguang',
        component: Tuiguang,
        meta: { title: '推广数据' }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { title: '期货公司管理后台' }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: NotFound,
    meta: { title: '期货公司管理后台' }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
