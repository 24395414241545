<template>
  <div class="wrapper">
    <v-sidebar></v-sidebar>
    <div class="content-body">
      <div class="content-header">
        <div class="flex at_c">
          <img class="head_title"
               src="@/assets/img/head_title.png"
               alt="">
        </div>
        <div class="flex at_c">
          <el-dropdown>
            <div class="flex at_c">
              <img class="account_avatar"
                   :src="userInfo.avatar"
                   alt="">
              <div class="account_name">{{userInfo.name}}</div>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <el-button type="danger"
                             @click="signOut">退出登录</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <div class="cori-content-box">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import vSidebar from "./Sidebar.vue";
export default {
  data() {
    return {
      bsList: [],
      userInfo: {},
      bs_id: null,
    };
  },
  components: {
    vSidebar,
  },
  created() {},
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("qihuo_info"));
  },
  methods: {
    signOut() {
      localStorage.removeItem("qihuo_token");
      localStorage.removeItem("qihuo_info");
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
}

.content-body {
  /* position: fixed;
  left: 260px;
  top: 0;
  bottom: 0; */
  width: calc(100% - 260px);
  min-width: 940px;
  height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

.content-header {
  width: 100%;
  height: 80px;
  background: linear-gradient(to right, #cfdafa, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 40px;
}

.cori-content-box {
  width: 100%;
  height: calc(100% - 80px);
  background-color: #f5f5f5;
  box-sizing: border-box;
  padding: 40px;
  overflow-y: auto;
}

.head_title {
  height: 30px;
  display: block;
}

.account_avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  object-fit: cover;
  border: 1px solid #ececec;
  box-sizing: border-box;
}

.account_name {
  margin-left: 20px;
  font-weight: 400;
  font-size: 24px;
  color: #707070;
  cursor: default;
}

.sign_out {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  background-color: #d42c2c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}
</style>
