<template>
  <div class="body">
    <div class="body_left">
      <div class="body_left_block_1">
        <div class="left_block_1_header">
          <img class="icon_welcome"
               src="@/assets/img/icon_welcome.png"
               alt="">
          欢迎回来，{{userInfo.name}}
        </div>
        <div class="left_block_1_datas">
          <div class="left_block_1_data_cell">
            <img class="icon_index_data"
                 src="@/assets/img/icon_index_data1.png"
                 alt="">
            <div class="">
              <div class="data_name">本月入口点击总数</div>
              <div>
                <span class="data_num">{{workbenchInfo.adMonthData}}</span>
                <span class="data_unit">个</span>
              </div>
            </div>
          </div>
          <div class="left_block_1_data_cell">
            <img class="icon_index_data"
                 src="@/assets/img/icon_index_data2.png"
                 alt="">
            <div class="">
              <div class="data_name">本月总开户咨询量</div>
              <div>
                <span class="data_num">{{workbenchInfo.openAccountData}}</span>
                <span class="data_unit">个</span>
              </div>
            </div>
          </div>
          <div class="left_block_1_data_cell">
            <img class="icon_index_data"
                 src="@/assets/img/icon_index_data3.png"
                 alt="">
            <div class="">
              <div class="data_name">日点击总数</div>
              <div>
                <span class="data_num">{{workbenchInfo.adDayData}}</span>
                <span class="data_unit">个</span>
              </div>
            </div>
          </div>
          <div class="left_block_1_data_cell">
            <img class="icon_index_data"
                 src="@/assets/img/icon_index_data4.png"
                 alt="">
            <div class="">
              <div class="data_name">较昨日新增</div>
              <div class="flex at_c">
                <span class="data_num">{{workbenchInfo.adDddition}}%</span>
                <img class="icon_trend"
                     v-if="workbenchInfo.adDddition > 0"
                     src="@/assets/img/icon_zhang.png"
                     alt="" />
                <img class="icon_trend"
                     v-if="workbenchInfo.adDddition < 0"
                     src="@/assets/img/icon_die.png"
                     alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="left_echarts_header">
            <div class="name">
              <span>开户数据</span>
              <span>（近7日）</span>
            </div>
            <div class="view_more can"
                 @click="viewMore">查看更多</div>
          </div>
          <div id="echarts_line1"></div>
        </div>
      </div>
    </div>
    <div class="body_right">
      <div class="body_right_block_1">
        <div class="body_right_block_1_header">
          <div>日期筛选</div>
          <el-date-picker @change="changeDate1"
                          v-model="date1"
                          type="daterange"
                          range-separator="-"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          format="YYYY/MM/DD"
                          value-format="YYYY-MM-DD"
                          popper-class="tzy-popper"
                          size="default" />
        </div>
        <div id="echarts_line2"></div>
      </div>
      <div class="body_right_block_2">
        <div class="body_right_block_2_header">
          <div>日期筛选</div>
          <el-date-picker @change="changeDate2"
                          v-model="date2"
                          type="daterange"
                          range-separator="-"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          format="YYYY/MM/DD"
                          value-format="YYYY-MM-DD"
                          popper-class="tzy-popper"
                          size="default" />
        </div>
        <div id="echarts_pie"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import * as Service from "@/api/common";
export default {
  components: {},
  data() {
    return {
      userInfo: {},
      workbenchInfo: {},
      date1: "",
      date2: "",
    };
  },
  created() {},
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("qihuo_info"));
    this.getWorkbench();
    this.openUserCharts();
    this.adAndOpenPhoneCharts();
    this.platformCharts();
  },
  methods: {
    viewMore() {
      this.$router.push({ path: "/kaihu" });
    },
    changeDate1(e) {
      this.adAndOpenPhoneCharts();
    },
    changeDate2(e) {
      this.platformCharts();
    },
    async getWorkbench() {
      const res = await Service.getWorkbench();
      this.workbenchInfo = res.data;
    },
    async openUserCharts() {
      const res = await Service.openUserCharts();
      this.echartsLine1(res.data);
    },
    async adAndOpenPhoneCharts() {
      var start_time = "";
      var end_time = "";
      if (this.date1) {
        start_time = this.date1[0];
        end_time = this.date1[1];
      }
      const res = await Service.adAndOpenPhoneCharts({
        start_time,
        end_time,
      });
      this.echartsLine2(res.data);
    },
    async platformCharts() {
      var start_time = "";
      var end_time = "";
      if (this.date2) {
        start_time = this.date2[0];
        end_time = this.date2[1];
      }
      const res = await Service.platformCharts({
        start_time,
        end_time,
      });
      this.echartsPie(res.data);
    },
    echartsLine1(data) {
      if (document.getElementById("echarts_line1") == null) {
        return;
      }
      echarts.dispose(document.getElementById("echarts_line1"));
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById("echarts_line1"));
      myChart.clear();
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          // backgroundColor: 'rgba(0,0,0,0.6)',
          // textStyle: {
          //   color: '#fff'
          // }
        },
        grid: {
          top: 60,
          left: 70,
          right: 50,
          bottom: 50,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          // data: [
          //   "2022-10-11",
          //   "2022-10-12",
          //   "2022-10-13",
          //   "2022-10-14",
          //   "2022-10-15",
          //   "2022-10-16",
          //   "2022-10-17",
          // ],
          data: data.xList,
          scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度
          axisLine: {
            // x轴
            show: true,
          },
          axisTick: {
            // x轴刻度线
            show: true,
          },
          axisLabel: {
            textStyle: {
              color: "#86909C",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度
            nameLocation: "end",
            nameTextStyle: {
              color: "#86909C",
              fontSize: 12,
              //  align:"left"
            },
            axisLine: {
              // y轴线
              show: true,
            },
            axisTick: {
              // y轴刻度
              show: true,
            },
            axisLabel: {
              // show :false,
              color: "#86909C",
            },
            splitLine: {
              lineStyle: {
                color: "#E5E6EB",
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            // name: 'rb2205-rb2210:',
            name: "开户数:",
            type: "line",
            smooth: true, // 是否为平滑曲线
            // data: [1120, 132, 101, 134, 90, 230, 210],
            data: data.yList,
            showSymbol: false, // 拐点tooltip hover时候才显示出来
            color: "#7449FB",
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#7449FB", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "transparent", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      });
    },
    echartsLine2(data) {
      if (document.getElementById("echarts_line2") == null) {
        return;
      }
      echarts.dispose(document.getElementById("echarts_line2"));
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById("echarts_line2"));
      myChart.clear();
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          // backgroundColor: 'rgba(0,0,0,0.6)',
          // textStyle: {
          //   color: '#fff'
          // }
        },
        legend: {
          bottom: 20,
        },
        grid: {
          top: 40,
          left: 70,
          right: 50,
          bottom: 70,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          // data: ["1月", "2月", "3月", "4月", "5月"],
          data: data.xList,
          scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度
          axisLine: {
            // x轴
            show: true,
          },
          axisTick: {
            // x轴刻度线
            show: true,
          },
          axisLabel: {
            textStyle: {
              color: "#86909C",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度
            nameLocation: "end",
            nameTextStyle: {
              color: "#86909C",
              fontSize: 12,
              //  align:"left"
            },
            axisLine: {
              // y轴线
              show: false,
            },
            axisTick: {
              // y轴刻度
              show: false,
            },
            axisLabel: {
              // show :false,
              color: "#86909C",
            },
            splitLine: {
              lineStyle: {
                color: "#E5E6EB",
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度
            nameLocation: "end",
            nameTextStyle: {
              color: "#86909C",
              fontSize: 12,
              //  align:"left"
            },
            axisLine: {
              // y轴线
              show: false,
            },
            axisTick: {
              // y轴刻度
              show: false,
            },
            axisLabel: {
              // show :false,
              color: "#86909C",
            },
            splitLine: {
              lineStyle: {
                color: "#E5E6EB",
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            // name: 'rb2205-rb2210:',
            name: "广告点击数",
            type: "bar",
            smooth: false, // 是否为平滑曲线
            // data: [1120, 132, 101, 134, 90],
            data: data.yList,
            showSymbol: true, // 拐点tooltip hover时候才显示出来
            color: "#4571E7",
            barMaxWidth: 28,
          },
          {
            // name: 'rb2205-rb2210:',
            name: "开户咨询量",
            type: "line",
            smooth: false, // 是否为平滑曲线
            // data: [1120, 132, 101, 134, 90],
            data: data.y2List,
            showSymbol: true, // 拐点tooltip hover时候才显示出来
            color: "#FF9100",
            yAxisIndex: 1,
          },
        ],
      });
    },
    echartsPie(data) {
      if (document.getElementById("echarts_pie") == null) {
        return;
      }
      echarts.dispose(document.getElementById("echarts_pie"));
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById("echarts_pie"));
      myChart.clear();
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "horizontal",
          bottom: 20,
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            center: ["50%", "45%"],
            color: ["#FFAA00", "#2CC3F2", "#4FAA0A", "#4571E7"],
            // data: [
            //   { value: 1048, name: "安卓" },
            //   { value: 735, name: "IOS" },
            //   { value: 580, name: "小程序" },
            //   { value: 484, name: "PC" },
            // ],
            data,
            label: {
              formatter: "{b}\n\n{d}%",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
  },
};
</script>
<style>
.tzy-popper {
  margin-left: -270px !important;
}
</style>
<style scoped>
.body {
  width: 100%;
  display: flex;
}

.body_left {
  width: calc(100% - 500px);
  min-width: 540px;
}

.body_left_block_1 {
  width: 100%;
  height: 872px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 8px 30px;
  border-radius: 20px;
}

.left_block_1_header {
  width: 100%;
  box-sizing: border-box;
  padding: 24px 0;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  color: #1c1c1c;
  border-bottom: 1px solid #ececec;
}

.icon_welcome {
  width: 28px;
  height: 28px;
  display: block;
  margin-right: 12px;
}

.left_block_1_datas {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #ececec;
  overflow-x: auto;
}

.left_block_1_data_cell {
  width: 25%;
  height: 100%;
  min-width: 240px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 32px 0 26px;
  padding: 0 10px 0 33px;
}

.left_block_1_data_cell:not(:last-child) {
  border-right: 1px solid #ececec;
}

.icon_index_data {
  width: 36px;
  height: 36px;
  display: block;
  margin-right: 20px;
}

.data_name {
  font-weight: 400;
  font-size: 14px;
  color: #a2a2a2;
  margin-bottom: 16px;
}

.data_num {
  font-weight: bold;
  font-size: 20px;
  color: #1c1c1c;
  margin-right: 20px;
}

.data_unit {
  font-weight: 400;
  font-size: 12px;
  color: #a2a2a2;
}

.icon_trend {
  height: 10px;
  display: block;
}

.left_echarts_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}

.left_echarts_header .name span:first-child {
  font-weight: 400;
  font-size: 18px;
  color: #1c1c1c;
}

.left_echarts_header .name span:last-child {
  font-weight: 400;
  font-size: 12px;
  color: #a2a2a2;
}

.left_echarts_header .view_more {
  font-weight: 400;
  font-size: 12px;
  color: #4571e7;
}

#echarts_line1 {
  width: 100%;
  height: 605px;
}

.body_right {
  width: 470px;
  margin-left: 30px;
}

.body_right_block_1 {
  width: 470px;
  height: 420px;
  border-radius: 20px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 25px 20px;
  margin-bottom: 30px;
}

.body_right_block_1_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  color: #707070;
}

#echarts_line2 {
  width: 100%;
  height: 368px;
}

.body_right_block_2 {
  width: 470px;
  height: 420px;
  border-radius: 20px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 25px 20px;
}

.body_right_block_2_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  color: #707070;
}

#echarts_pie {
  width: 100%;
  height: 368px;
}
</style>