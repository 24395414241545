<template>
  <div>
    <div class="search_header">
      <div class="flex at_c mr30">
        <div class="mr10">广告位置</div>
        <el-select v-model="positionVal"
                   placeholder="">
          <el-option v-for="item in positionList"
                     :key="item.val"
                     :label="item.name"
                     :value="item.val">
          </el-option>
        </el-select>
      </div>
      <div class="flex at_c mr30">
        <div class="mr10">日期筛选</div>
        <el-date-picker v-model="timeArr"
                        type="daterange"
                        unlink-panels
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="YYYY/MM/DD"
                        value-format="YYYY-MM-DD"
                        :shortcuts="shortcuts" />
      </div>
      <div>
        <el-button @click="searchList"
                   type="primary">查询</el-button>
        <el-button @click="reset"
                   type="">重置</el-button>
      </div>
    </div>
    <div class="tabel_data">
      <el-table :data="tableData"
                height="66vh"
                style="width: 100%"
                v-loading="loading"
                border>
        <el-table-column prop="create_time"
                         label="时间"
                         align="center"
                         width="180" />
        <el-table-column label="广告位置"
                         align="center"
                         width="120">
          <template #default="scope">
            <span class="platform_text">{{scope.row.platform}}</span>
          </template>
        </el-table-column>
        <el-table-column label="走势"
                         align="center"
                         width="180">
          <template #default="scope">
            <div class="flex at_c jc_c can"
                 @click="adCharts(scope.$index)">
              <img class="icon_zoushi"
                   src="@/assets/img/icon_zoushi.png"
                   alt="">近一周走势
            </div>
          </template>
        </el-table-column>
        <el-table-column label="数值"
                         align="center"
                         width="180"
                         prop="number">

        </el-table-column>
        <el-table-column label="备注"
                         align="center"
                         :show-overflow-tooltip="true">
          <template #default="scope">
            <span>{{scope.row.remark || '暂无备注'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         align="center"
                         fixed="right"
                         width="180">
          <template #default="scope">
            <span @click="showForm(scope.$index)"
                  class="btn_add">添加备注</span>
            <!-- <span class="btn_del">删除</span> -->
          </template>
        </el-table-column>
      </el-table>

      <div class="flex at_c jc_e mt20">
        <el-pagination background
                       layout="total, prev, pager, next"
                       :current-page="page"
                       :page-size="limit"
                       :total="total"
                       @current-change="handleCurrentChange" />
      </div>
    </div>

    <el-dialog v-model="dialogFormVisible"
               title="添加备注"
               width="420px"
               @closed="closeForm">
      <el-form :model="form">
        <el-form-item label="备注"
                      :label-width="formLabelWidth">
          <el-input v-model="form.remark"
                    placeholder="暂无备注"
                    autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeForm">取消</el-button>
          <el-button type="primary"
                     @click="submit">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="dialogEchartsVisible"
               :title="echartsTitle"
               width="620px"
               @closed="closeEcharts">
      <div id="echarts_line"></div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { dateSwtichTimeStamp, formatTime } from "@/utils/common";
import * as Service from "@/api/common";
export default {
  data() {
    return {
      positionList: [
        {
          name: "全部",
          val: "",
        },
        {
          name: "安卓",
          val: "安卓",
        },
        {
          name: "IOS",
          val: "IOS",
        },
        {
          name: "PC",
          val: "PC",
        },
        {
          name: "小程序",
          val: "小程序",
        },
      ],
      positionVal: "",
      timeArr: "",
      shortcuts: [
        {
          text: "今日",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
            return [end, start];
          },
        },
        {
          text: "昨日",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            return [start, end];
          },
        },
        {
          text: "最近7天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "最近30天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      limit: 0,
      loading: false,

      dialogFormVisible: false,
      formLabelWidth: "80px",
      form: {
        platform: "",
        create_time: "",
        remark: "",
      },

      dialogEchartsVisible: false,
      echartsTitle: "",
    };
  },
  mounted() {
    this.getAdDataList(1);
  },
  methods: {
    async getAdDataList(page) {
      this.loading = true;
      var start_time = "";
      var end_time = "";
      if (this.timeArr) {
        start_time = this.timeArr[0];
        end_time = this.timeArr[1];
      }
      const res = await Service.getAdDataList({
        page,
        position: this.positionVal,
        start_time,
        end_time,
      });
      var tableData = res.data.data;
      var arr = [];
      for (const item of tableData) {
        for (const items of item.data.data) {
          arr.push({
            create_time: item.create_time,
            platform: items.platform,
            number: items.number,
            remark: items.remark,
          });
        }
      }
      this.tableData = arr;
      this.total = res.data.total;
      this.limit = res.data.per_page;
      this.loading = false;
    },
    searchList() {
      this.page = 1;
      this.tableData = [];
      this.getAdDataList(1);
    },
    reset() {
      this.positionVal = "";
      this.timeArr = "";
      this.searchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.tableData = [];
      this.getAdDataList(page);
    },
    showForm(index) {
      var tableData = this.tableData;
      this.form.platform = tableData[index].platform;
      this.form.create_time = tableData[index].create_time;
      this.form.remark = tableData[index].remark;
      this.dialogFormVisible = true;
    },
    closeForm() {
      this.dialogFormVisible = false;
      this.form.platform = "";
      this.form.create_time = "";
      this.form.remark = "";
    },
    async submit() {
      var platform = this.form.platform;
      var create_time = this.form.create_time;
      var remark = this.form.remark;
      if (!remark) {
        this.$message.error("备注不可为空");
        return false;
      }
      const res = await Service.addAdRemark({
        platform,
        create_time,
        remark,
      });
      this.$message.success(res.msg);
      this.closeForm();
      this.getAdDataList();
    },
    async adCharts(index) {
      var tableData = this.tableData;
      var end_time = tableData[index].create_time;
      var start_time = formatTime(
        dateSwtichTimeStamp(end_time) / 1000 - 3600 * 24 * 7,
        "Y-M-D"
      );
      const res = await Service.adCharts({
        platform: tableData[index].platform,
        start_time,
        end_time,
      });
      console.log("res", res);
      this.dialogEchartsVisible = true;
      this.echartsTitle =
        tableData[index].platform +
        "：" +
        start_time +
        "~" +
        end_time +
        "走势图";
      setTimeout(() => {
        this.echartsLine1(res.data);
      }, 500);
    },
    closeEcharts() {
      this.dialogEchartsVisible = false;
      this.echartsTitle = "";
      const myChart = echarts.init(document.getElementById("echarts_line"));
      myChart.clear();
    },
    echartsLine1(data) {
      if (document.getElementById("echarts_line") == null) {
        return;
      }
      echarts.dispose(document.getElementById("echarts_line"));
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById("echarts_line"));
      myChart.clear();
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          // backgroundColor: 'rgba(0,0,0,0.6)',
          // textStyle: {
          //   color: '#fff'
          // }
        },
        grid: {
          top: 60,
          left: 70,
          right: 50,
          bottom: 50,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          // data: [
          //   "2022-10-11",
          //   "2022-10-12",
          //   "2022-10-13",
          //   "2022-10-14",
          //   "2022-10-15",
          //   "2022-10-16",
          //   "2022-10-17",
          // ],
          data: data.xList,
          scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度
          axisLine: {
            // x轴
            show: true,
          },
          axisTick: {
            // x轴刻度线
            show: true,
          },
          axisLabel: {
            textStyle: {
              color: "#86909C",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度
            nameLocation: "end",
            nameTextStyle: {
              color: "#86909C",
              fontSize: 12,
              //  align:"left"
            },
            axisLine: {
              // y轴线
              show: true,
            },
            axisTick: {
              // y轴刻度
              show: true,
            },
            axisLabel: {
              // show :false,
              color: "#86909C",
            },
            splitLine: {
              lineStyle: {
                color: "#E5E6EB",
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            // name: 'rb2205-rb2210:',
            name: "点击数:",
            type: "line",
            smooth: true, // 是否为平滑曲线
            // data: [1120, 132, 101, 134, 90, 230, 210],
            data: data.yList,
            showSymbol: false, // 拐点tooltip hover时候才显示出来
            color: "#7449FB",
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#7449FB", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "transparent", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.search_header {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 20px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  color: #707070;
}

.tabel_data {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
}

.platform_text {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
}

.icon_zoushi {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: 12px;
}

.btn_add {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
  cursor: pointer;
}

.btn_del {
  font-weight: 400;
  font-size: 14px;
  color: #ff1d24;
  cursor: pointer;
}

#echarts_line {
  width: 100%;
  height: 350px;
}
</style>