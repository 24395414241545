<template>
  <div>
    <!-- <div class="search_header">
      <div class="flex at_c mr30">
        <div class="mr10">日期筛选</div>
        <el-date-picker v-model="timeArr"
                        type="daterange"
                        unlink-panels
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="YYYY/MM/DD"
                        value-format="YYYY-MM-DD"
                        :shortcuts="shortcuts" />
      </div>
      <div>
        <el-button @click="searchList"
                   type="primary">查询</el-button>
        <el-button @click="reset"
                   type="">重置</el-button>
      </div>
    </div> -->

    <div class="table_data">
      <div class="table_data_div">
        <div class="table_head">
          <div>时间</div>
          <div>渠道</div>
          <div>手机号</div>
        </div>
        <div v-if="tableData.length" v-loading="loading"
             class="table_data_list"
             v-infinite-scroll="load1"
             :infinite-scroll-delay="500">
          <div v-for="(item,index) in tableData"
               :key="index"
               class="infinite-list-item">
            <div>{{item.create_time}}</div>
            <div>{{item.source_text}}</div>
            <div>
              <div v-if="currentIndex == index" class="mr10">{{item.mobile}}</div>
              <div v-if="currentIndex != index" class="mr10">{{item.mobileText}}</div>
              <!-- <el-button type="primary" size="small" @click="currentIndex = index">查看</el-button> -->
            </div>
          </div>
        </div>
        <div v-if="!tableData.length" class="empty_content">暂无数据</div>
      </div>
      <!-- <el-table :data="tableData"
                height="70vh"
                style="width: 100%"
                v-loading="loading"
                border>
        <el-table-column prop="create_time"
                         label="时间"
                         align="center" />
        <el-table-column prop="source_text"
                         label="渠道"
                         align="center" />
        <el-table-column prop="mobileText"
                         label="手机号"
                         align="center" />
      </el-table> -->

    </div>
  </div>
</template>

<script>
import * as Service from "@/api/common";
export default {
  data() {
    return {
      timeArr: "",
      shortcuts: [
        {
          text: "今日",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
            return [end, start];
          },
        },
        {
          text: "昨日",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            return [start, end];
          },
        },
        {
          text: "最近7天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "最近30天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      limit: 0,
      loading: false,

      dialogFormVisible: false,
      formLabelWidth: "80px",
      form: {
        name: "",
        mobile: "",
      },

      currentIndex: null
    };
  },
  mounted() {
    this.getClueDataList(1);
  },
  methods: {
    load1() {
      if (this.tableData.length) {
        this.page++;
        this.getClueDataList(this.page);
        console.log("下一页");
      }
      // this.page++;
    },
    async getClueDataList(page) {
      this.loading = true;
      var start_time = "";
      var end_time = "";
      if (this.timeArr) {
        start_time = this.timeArr[0];
        end_time = this.timeArr[1];
      }
      const res = await Service.getClueDataList({
        page,
        start_time,
        end_time,
      });
      var tableData_new = res.data.data;
      var tableData = [...this.tableData];
      if (tableData_new.length) {
        this.tableData = tableData.concat(tableData_new);
      } else {
        if (this.tableData.length) {
          this.$message.success("已经到底了");
        }
      }
      // this.total = res.data.total;
      // this.limit = res.data.per_page;
      this.loading = false;
    },
    searchList() {
      this.page = 1;
      this.tableData = [];
      this.getClueDataList(1);
    },
    reset() {
      this.timeArr = "";
      this.searchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.tableData = [];
      this.getClueDataList(page);
    },
    closeForm() {
      this.dialogFormVisible = false;
      this.form.name = "";
      this.form.mobile = "";
    },
    async submit() {
      var name = this.form.name;
      var mobile = this.form.mobile;
      if (!name) {
        this.$message.error("姓名不可为空");
        return false;
      }
      if (
        mobile.trim().length != 11 ||
        !/^1[3|4|5|6|7|8|9]\d{9}$/.test(mobile)
      ) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      const res = await Service.addOpenUser({
        name,
        mobile,
      });
      this.$message.success(res.msg);
      this.closeForm();
      this.getClueDataList();
    },
    // async copyText(e) {
    //   await navigator.clipboard.writeText(e);
    //   this.$message.success("已复制到剪贴板");
    // },
    copyText(text) {
      var textarea = document.createElement("textarea");
      textarea.style.position = "fixed";
      textarea.style.opacity = 0;
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message.success("已复制到剪贴板");
    },
  },
};
</script>

<style scoped>
.search_header {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 20px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  color: #707070;
}

.handle_btns {
  margin-bottom: 12px;
}

.btn_add_img {
  height: 32px;
  display: block;
  cursor: pointer;
}

.table_data {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
}

.table_data_div {
  width: 100%;
  height: 79vh;
  box-sizing: border-box;
  border: 1px solid #ebeef5;
}

.table_head {
  /* width: 100%; */
  width: calc(100% - 15px);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeef5;
}

.table_head > div {
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  color: #909399;
  font-weight: bold;
}

.table_head > div:not(:last-child) {
  border-right: 1px solid #ebeef5;
}

.table_data_list {
  width: 100%;
  height: calc(100% - 48px);
  overflow-y: auto;
}

.infinite-list-item {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* border-top: 1px solid #EBEEF5; */
}

.infinite-list-item > div {
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  color: #909399;
}

.infinite-list-item > div:not(:last-child) {
  border-right: 1px solid #ebeef5;
}

.infinite-list-item:not(:last-child) {
  border-bottom: 1px solid #ebeef5;
}

/* .table_data_list::-webkit-scrollbar {
  position: absolute;
  width: 6px;
  border-radius: 4px;
  right: 2px;
  bottom: 2px;
  background-color: rgba(0, 0, 0, 0.3);
} */

.empty_content {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #909399;
}

.platform_text {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
}

.icon_zoushi {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: 12px;
}

.btn_add {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
  cursor: pointer;
}

.btn_del {
  font-weight: 400;
  font-size: 14px;
  color: #ff1d24;
  cursor: pointer;
}

.status1 {
  font-weight: 400;
  font-size: 14px;
  color: #ffa200;
  margin-right: 30px;
}

.btn_copy {
  width: 56px;
  height: 24px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #4571e7;
}

.status2 {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
  margin-right: 30px;
}

.kaihuhao {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
}
</style>