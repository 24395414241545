<template>
  <div>
    <div class="search_header">
      <div class="flex at_c mr30">
        <div class="mr10">广告位置</div>
        <el-select v-model="positionVal"
                   placeholder="">
          <el-option v-for="item in positionList"
                     :key="item.val"
                     :label="item.name"
                     :value="item.val">
          </el-option>
        </el-select>
      </div>
      <div class="flex at_c mr30">
        <div class="mr10">日期筛选</div>
        <el-date-picker v-model="timeArr"
                        type="daterange"
                        unlink-panels
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="YYYY/MM/DD"
                        value-format="YYYY-MM-DD"
                        :shortcuts="shortcuts" />
      </div>
      <div>
        <el-button @click="searchList"
                   type="primary">查询</el-button>
        <el-button @click="reset"
                   type="">重置</el-button>
      </div>
    </div>
    <div class="tabel_data">
      <el-table :data="tableData"
                height="66vh"
                style="width: 100%"
                v-loading="loading"
                border>
        <!-- <el-table-column prop="id"
                         label="ID"
                         align="center"
                         width="180" /> -->
        <el-table-column prop="create_time"
                         label="预约时间"
                         align="center"
                         width="180" />
        <el-table-column label="广告位置"
                         align="center"
                         width="120">
          <template #default="scope">
            <span class="platform_text">{{scope.row.platform}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobileText"
                         label="预约手机号"
                         align="center"
                         width="180">
        </el-table-column>
        <el-table-column label="是否已跟进"
                         align="center"
                         fixed="right"
                         width="180">
          <template #default="scope">
            <el-tooltip :content="'是否已跟进:' + (scope.row.status==1?'是':'否')"
                        placement="top">
              <el-switch @click="changeCall(scope.$index,scope.row)"
                         :value="scope.row.status"
                         :disabled="scope.row.callDisabled"
                         active-color="#4373E1"
                         inactive-color="#E2E2E2"
                         :active-value="1"
                         :inactive-value="0">
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <div class="flex at_c jc_e mt20">
        <el-pagination background
                       layout="total, prev, pager, next"
                       :current-page="page"
                       :page-size="limit"
                       :total="total"
                       @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "@/api/common";
export default {
  data() {
    return {
      positionList: [
        {
          name: "全部",
          val: "",
        },
        {
          name: "安卓",
          val: "安卓",
        },
        {
          name: "IOS",
          val: "IOS",
        },
        {
          name: "PC",
          val: "PC",
        },
        {
          name: "小程序",
          val: "小程序",
        },
      ],
      positionVal: "",
      timeArr: "",
      shortcuts: [
        {
          text: "今日",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
            return [end, start];
          },
        },
        {
          text: "昨日",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            return [start, end];
          },
        },
        {
          text: "最近7天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "最近30天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      limit: 0,
      loading: false,
    };
  },
  mounted() {
    this.getOpenAccountDataList(1);
  },
  methods: {
    async getOpenAccountDataList(page) {
      this.loading = true;
      var start_time = "";
      var end_time = "";
      if (this.timeArr) {
        start_time = this.timeArr[0];
        end_time = this.timeArr[1];
      }
      const res = await Service.getOpenAccountDataList({
        page,
        position: this.positionVal,
        start_time,
        end_time,
      });
      var tableData = res.data.data;
      const reg = /(\d{3})\d{4}(\d{4})/;
      for (const item of tableData) {
        item.callDisabled = false;
        item.mobileText = item.mobile.replace(reg, "$1****$2")
      }
      this.tableData = tableData;
      this.total = res.data.total;
      this.limit = res.data.per_page;
      this.loading = false;
    },
    // 修改是否拨打
    changeCall(index, row) {
      if (!row.callDisabled) {
        this.changeCallApi(index, row);
      }
      this.tableData[index].callDisabled = true;
      setTimeout(() => {
        this.tableData[index].callDisabled = false;
      }, 800);
    },
    async changeCallApi(index, row) {
      const res = await Service.changeOpenPhoneStatus({
        id: row.id,
        status: row.status == 1 ? 0 : 1,
      });
      this.getOpenAccountDataList(this.page);
      this.$message.success(res.msg);
    },
    searchList() {
      this.page = 1;
      this.tableData = [];
      this.getOpenAccountDataList(1);
    },
    reset() {
      this.positionVal = "";
      this.timeArr = "";
      this.searchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.tableData = [];
      this.getOpenAccountDataList(page);
    },
  },
};
</script>

<style scoped>
.search_header {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 20px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  color: #707070;
}

.tabel_data {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
}

.platform_text {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
}

.icon_zoushi {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: 12px;
}

.btn_add {
  font-weight: 400;
  font-size: 14px;
  color: #4571e7;
  cursor: pointer;
}

.btn_del {
  font-weight: 400;
  font-size: 14px;
  color: #ff1d24;
  cursor: pointer;
}
</style>